import React, { useEffect, useState, useRef } from "react";
//import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Slider from "react-slick";
import ReactModal from "react-modal";
import SelectReact from "react-select";
import makeAnimated from "react-select/animated";
import TopNavigation from "../../components/common/TopNavigation/component";
import { Edit } from "../../components/svgIcons";
import { Delete } from "../../components/svgIcons";
import { Spin, notification, Tooltip } from "antd";
import { size, isEmpty, sortBy, set } from "lodash";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";
import moment from "moment";
import CheckboxComponent from "../../components/common/CheckBox/categoriesComponent";
import Checkbox from "../../components/common/CheckBox/tagsComponent";
import CSVReader from "react-csv-reader";
import PrevArrow from "../../images/LeftArrow";
import NextArrow from "../../images/RightArrow";
import { Close } from "../../components/svgIcons";
import axios from "axios";
import { CSVLink } from "react-csv";


let cancelToken;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function AdminComponent(props) {
  const { Option } = Select;
  let emails = [];

  const [emailError, setEmailError] = useState("");
  const [initialState, setInitialState] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [userData, setUserData] = useState({});
  const [tab, setTab] = useState("news");
  const [isCalled, setIsCalled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [primaryModalIsOpen, setPrimaryIsOpen] = useState(false);
  const [secondaryModalIsOpen, setSecondaryIsOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [nextStep, setNextStep] = useState(0);
  const [ltWeeklyNextStep, setLtWeeklyNextStep] = useState(0);
  const [tagsList, setTagsList] = useState([]);
  const [tagsLoader, setTagsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [LTWeeklyThemePage, setLtWeeklyThemePage] = useState(1);
  const [LTWeeklyDate, setLTweeklyDate] = useState([]);
  const [curatedList, setCuratedList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [curatedArray, setCuratedArray] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([
    "Artificial Intelligence/Machine Learning",
    "Broadcast/Cable Networks",
    "Cable/Pay-TV/Wireless",
    "Cloud/Datacenters/IT Infrastructure",
    "eCommerce/Social Commerce/Retail",
    "Handheld Devices/Connected Home/Accessories",
    "Macro Updates",
    "Other (Technology)",
    "Regulatory",
    "Satellite/Space",
    "Social/Digital Media",
    "Software",
    "Tech Hardware",
    "Towers/Fiber",
    "Video Streaming",
  ]);
  const [finalCategories, setFinalCategories] = useState([]);
  const [finalTags, setFinalTags] = useState([]);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [selectedthemes, setSelectedThemes] = useState([]);
  const [allCheckedTheme, setAllCheckedTheme] = useState(true);
  const [lTWeeklyThemeData, setLtWeeklyThemeData] = useState(true);
  const [themeCategoriesData, setThemeCategoriesData] = useState([]);
  const [isExists, setIsExist] = useState(false);
  let selectedThemeCategories = [];
  const [secondaryTag, setSecondaryTag] = useState([]);
  const [tagLoader, setTagLoader] = useState(false);
  const [bulkPrimaryTag, setBulkPrimaryTag] = useState({});
  const [bulkSecondaryTag, setBulkSecondayTag] = useState([]);
  const [mailModalIsOpen, setMailModalIsOpen] = useState(false);
  const [mailName, setMailName] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mailListLoader, setMailListLoader] = useState(false);
  const [mailingListData, setMailingListData] = useState([]);
  const [nextMailingStep, setNextMailingStep] = useState(0);
  const [mailingListPage, setMailingListPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedRecord, setDeletedRecord] = useState({});
  const [nameError, setNameError] = useState("");
  const [campaignsLoader, setCampaignsLoader] = useState(false);
  const [campaignsListPage, setCampaignsListPage] = useState(1);
  const [nextCamapaignStep, setNextCamapaignStep] = useState(0);
  const [campaignsList, setCampaignList] = useState([]);
  const [editMailModalOpen, setEditMailOpen] = useState(false);
  const [mailingData, setMailingData] = useState({});
  const [subTab, setSubTab] = useState("iim-weeklies");
  const [userDetails, setUserDetails] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [dataList, saveDataList] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [bulkErrorMessage, setErrorBulktag] = useState("");
  const [campaignType, setCampaignType] = useState("Live");
  // const [campaignType, setCampaignType] = useState("Live");
  const [FreeFormPageNumber, setFreeFormPageNumber] = useState(1);
  const [FreeFormTotalCount, setFreeFormTotalCount] = useState(0);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const [isexportLoader,setExportLoader]=useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
  
const [editTagNameModal,seteditTagNameModal] = useState(false)
const [tagName,setTagName] = useState({})
const [oldTagName,setOldTagName]=useState("")
const [newTagName,setNewTagName]=useState("")
const [headerCategories,setHeaderCategories]=useState([])
const [createTag,setCreateTag]=useState("")
const [createTagModalIsOpen,setCreateTagModalIsOpen]=useState(false)
const [selectedHeaderCategory,setselectedHeaderCategory]=useState("")
const [headerCategoryId, setHeaderCategoryId] = useState("")
const [newsCategoryData,setNewsCategoryData]=useState([])
const [deleteTagNameModal,setdeleteTagNameModal] = useState(false)
const [tagNameError,setTagNameError]=useState(false)
const [deleteTagId,setDeleteTagId] = useState(false)
const [webAccessLoader,setWebAccessLoader]=useState(false)

  let sliderRef = useRef(null);
  const next = () => {
    if (imageIndex === lTWeeklyThemeData.length - 1) {
      setIsNextDisabled(true);
      return;
    }
    const newIndex = imageIndex + 1;
    if (newIndex === lTWeeklyThemeData.length - 1) {
      setIsNextDisabled(true);
    }
    setImageIndex(newIndex);
  };
  const previous = () => {
    if (imageIndex === 0) {
      setIsPrevDisabled(true);
      return;
    }
    const newIndex = imageIndex - 1;
    if (newIndex === 0) {
      setIsPrevDisabled(true);
    }

    setImageIndex(newIndex);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
  const [searchParams] = useSearchParams();
  const params = useParams();

  const [freeFormCampaignType, setFreeFormCampaignType] = useState("Live");

  const ref = useRef();

  const fileHandler = async (records) => {
    setData(records);
    setCancel(true);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
      switch (header) {
        case "EMAIL":
          return "Email";
        case "FIRST_NAME":
          return "FirstName";
        case "LAST_NAME":
          return "LastName";
        case "CONTACT_ID":
          return "UserListId";
        case "Email":
          return "Email";
        case "FirstName":
          return "FirstName";
        case "LastName":
          return "LastName";
        case "FullName":
          return "FullName";

        // Add more cases for other headers
        default:
          return header.replace(/\W/g, "_");
      }
    },
  };

  const isCancel = () => {
    setData([]);
    setCancel(false);
  };

  const callApi = async () => {
    const { value } = await props.getNews({ page: 1, count: 20 });
    if (value?.Results?.length > 0) {
      setNewsData(value?.Results);
      let nextPageCount = parseInt(Math.ceil(value?.totalCount / 20));
      setNextStep(nextPageCount);
      setLoader(false);
    }
  };

  const callCuratedTagApi = async () => {
    const { value } = await props.getCuratedTags();
    if (value?.Result?.length > 0) {
      setCuratedList(value?.Result);
      setLoader(false);
    }
  };

  const callGetUserDetails = async (id) => {
    const { value } = await props.getUserDetails(parseInt(id));
    if (!value?.IIMWeeklies) {
      changeTab("mailList");
    }
    setUserDetails(value);
  };

  const callMeApi = async () => {
    const { value } = await props.getMe();
    if (value) {
      setUserData(value);
      callGetUserDetails(value.Id);
    }
  };
  const callHeaderTagApi = async () => {
    const { value } = await props.getAllHeaderTags();
    if (value?.NewsCategoryHeaderies?.length > 0) {
      setNewsCategoryData(value?.NewsCategories)
      setHeaderCategories(value?.NewsCategoryHeaderies);
      setLoader(false);
    }
  };

  const getLtWeeklyThemeCategoriesApi = async () => {
    const { value } = await props.getLtWeeklyThemeCategories();
    if (value?.Result?.length > 0) {
      value.Result = sortBy(value.Result, (e) => e.Name?.toLowerCase());
      setThemeCategoriesData(value?.Result);
      let list = [];
      let prm = value?.Result?.map((r) => {
        let v = {
          value: r.Id,
          label: r.Title,
          color: "#00B8D9",
          isFixed: false,
        };
        list.push(v);
      });
      await Promise.all(prm);
      let updateList = list?.sort((a, b) => a.label.localeCompare(b.label))
      setSecondaryTag([...updateList]);
      // setPrimaryTag(value?.Result);
      setLoader(false);
    }
  };

  const CallApiForGetMailingList = async (page = mailingListPage) => {
    const { value } = await props.getMailingList({
      page: page,
      count: 20,
    });
    if (value?.Success) {
      setMailListLoader(false);
      let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 20));
      setNextMailingStep(nextPageCount);
      setMailingListData(value?.Result?.Data);
      setMailListLoader(false);
    } else if (value?.Result?.Data?.length === 0) {
      setNextMailingStep(0);
      setMailListLoader(false);
    }
  };

  const changeCampaignTab = (value) => {
    setCampaignType(value);
    setCampaignsLoader(true);
    getAllCampaigns(value);
  };

  const changeFreeFormCampaignTab = (value) => {
    setCampaignList([])
    setFreeFormCampaignType(value);
    // setCampaignsLoader(true);
    getAllCampaigns(value);
  };

  const getAllCampaigns = async (tab = campaignType) => {
    setCampaignsLoader(true)
    setCampaignList([])
    if(location.pathname.includes('/free-form-emails')){
      if(tab === 'test' || tab === 'Test'){
        tab = 'FreeFormTest'
      }else if(tab === 'Live' || tab === 'live'){
        tab = 'FreeFormLive'
      }else {
        return ""
      }
    }
    if(tab === ''){
      return ""
    }
    // if(location.search.includes('campaign=Test')){
    //   tab = 'Test'      
    // }else if(location.search.includes('campaign=Live')){
    //   tab = 'Live'      
    // }
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    
    const { value } = await props.getAllCampaigns({
      page: campaignsListPage,
      count: 10,
      campaignType: tab,
      token: cancelToken.token
    });
    if (value?.Success) {
      // else if(location.search.includes("campaign=Live") && location.pathname.includes('/free-form-emails')) {
      //   console.log('enterr-------->--', location.search)
      //   setFreeFormCampaignType("Live");
      //   navigate(`/free-form-emails?campaign=Live`)
      // } else if (location.search.includes("campaign=Test") && location.pathname.includes('/free-form-emails')) {
      //   console.log('enterr-------->--sasas')
      //   setFreeFormCampaignType("Test");
      //   navigate(`/free-form-emails?campaign=Test`)
      // }
      
      
      if (value?.Result?.length > 0) {
      setCampaignList([...value?.Result]);
      setFreeFormTotalCount(value?.Result[0]?.TotalCount);
      let nextPageCount = parseInt(
        Math.ceil(value?.Result[0]?.TotalCount / 10)
      );
      setNextCamapaignStep(nextPageCount);
      }
      setFreeFormPageNumber(1)
      setCampaignsLoader(false)
      // let clearTime = setInterval(()=>{
      //   setCampaignsLoader(false);
      //   clearInterval(clearTime)
      // }, 1000)

    }
  };

  const changeTab = async (tab) => {
    setTab(tab);
    if (tab === "news") {
      changeSubTab("iim-weeklies");
      // if(location.pathname)
      navigate('/iim-weeklies');
    } else if (tab === "theme") {
      navigate("/bulk-tag");
    } else if (tab === "campaigns") {
      // setCampaignsLoader(true)
      // getAllCampaigns()
      changeSubTab("capital-markets");
      navigate("/capital-markets");
    } else if (tab === "free-form-emails") {
      // setCampaignsLoader(true)
      // getAllCampaigns()
      // changeSubTab('capital-markets');
      // navigate("/free-form-emails");
    } else {
      setMailListLoader(true);
      CallApiForGetMailingList(mailingListPage);
      navigate("/mailing-list");
    }
  };

  const pageChange = async (value) => {
    setLoader(true);
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.getNews({
        page: pageNum,
        count: 20,
      });
      if (value?.Results?.length > 0) {
        let nextPageCount = parseInt(Math.ceil(value?.totalCount / 20));
        setNextStep(nextPageCount);
        setNewsData(value?.Results);
      }
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.getNews({
        page: pageNum,
        count: 20,
      });
      if (value?.Results?.length > 0) {
        let nextPageCount = parseInt(Math.ceil(value?.totalCount / 20));
        setNextStep(nextPageCount);
        setNewsData(value?.Results);
      }
    }
    setLoader(false);
  };

  // const pageChangeGetMailingList = async (value) => {
  //   setMailListLoader(true);
   
  //   if (value === "next") {
  //     let pageNum = mailingListPage + 1;
  //     console.log(pageNum,"pageCount?aa")

  //     setMailingListPage(pageNum);
  //     const { value } = await props.getMailingList({
  //       page: pageNum,
  //       count: 20,
  //     });
  //     console.log("Before state update - Page number:", pageNum)
  //     if (value?.Success) {
  //       console.log(value?.Result?.Data,'value?.Result?.Data')
  //       setMailingListData([...value?.Result?.Data]);
  //       let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 20));
  //       setNextMailingStep(nextPageCount);
  //       let clearLoaderState = setInterval(() => {
  //         setMailListLoader(false);
  //         clearInterval(clearLoaderState);
  //       }, 500);
  //     }
  //   } else if (value === "previous") {
  //    let  pageNum = mailingListPage - 1 === 0 ? 1 : mailingListPage - 1;
  //     console.log(pageNum,"pageCount?")

  //     setMailingListPage(pageNum);
  //     const { value } = await props.getMailingList({
  //       page: pageNum,
  //       count: 20,
  //     });
  //     if (value?.Success) {
  //       console.log(value?.Result?.Data,'value?.Result?.Data')

  //       setMailingListData([...value?.Result?.Data]);
  //       let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 20));
  //       setNextMailingStep(nextPageCount);
  //       let clearLoaderState = setInterval(() => {
  //         setMailListLoader(false);
  //         clearInterval(clearLoaderState);
  //       }, 500)

  //     }
  //   }
  // };
  const pageChangeGetMailingList = (direction) => {
    let newPage = mailingListPage;
    if (direction === "previous" && mailingListPage > 1) {
      newPage = mailingListPage - 1;
    } else if (direction === "next" && nextMailingStep > mailingListPage) {
      newPage = mailingListPage + 1;
    }
    setMailingListPage(newPage);
    setMailListLoader(true);
    CallApiForGetMailingList(newPage);
  };
  

  const pageChangeForLtWeekly = async (value) => {
    setLoader(true);
    if (value === "next") {
      let pageNum = LTWeeklyThemePage + 1;
      setLtWeeklyThemePage(pageNum);
      const { value } = await props.getLtWeeklyTheme({
        page: pageNum,
        count: 100,
      });
      if (value?.Success) {
        setLtWeeklyThemeData(value?.Result?.Data);
        let nextPageCount = parseInt(
          Math.ceil(value?.Result?.TotalCount / 100)
        );
        setLtWeeklyNextStep(nextPageCount);
      }
    } else if (value === "previous") {
      let pageNum = LTWeeklyThemePage - 1 === 0 ? 1 : LTWeeklyThemePage - 1;
      setLtWeeklyThemePage(pageNum);
      const { value } = await props.getLtWeeklyTheme({
        page: pageNum,
        count: 100,
      });
      if (value?.Success) {
        setLtWeeklyThemeData(value?.Result?.Data);
        let nextPageCount = parseInt(
          Math.ceil(value?.Result?.TotalCount / 100)
        );
        setLtWeeklyNextStep(nextPageCount);
      }
    }
    setLoader(false);
  };

  const pageChangeCampaignsList = async (value) => {
    setCampaignsLoader(true);
    let tab = campaignType
    if(location.pathname.includes('/free-form-emails')){
      tab = freeFormCampaignType
      if(tab === 'test' || tab === 'Test'){
        tab = 'FreeFormTest'
      }else{
        tab = 'FreeFormLive'
      }
    }
    if (value === "next") {
      let pageNum = campaignsListPage + 1;
      setCampaignsListPage(pageNum);
      const { value } = await props.getAllCampaigns({
        page: pageNum,
        count: 10,
        campaignType: tab,
      });
      if (value?.Success) {
        setCampaignList(value?.Result);
        if (value?.Result?.length > 0) {
          let nextPageCount = parseInt(
            Math.ceil(value?.Result[0]?.TotalCount / 10)
          );
          setNextCamapaignStep(nextPageCount);
        }
      }
    } else if (value === "previous") {
      let pageNum = campaignsListPage - 1 === 0 ? 1 : campaignsListPage - 1;
      setCampaignsListPage(pageNum);
      const { value } = await props.getAllCampaigns({
        page: pageNum,
        count: 10,
        campaignType: tab,
      });
      if (value?.Success) {
        setCampaignList(value?.Result);
        if (value?.Result?.length > 0) {
          let nextPageCount = parseInt(
            Math.ceil(value?.Result[0]?.TotalCount / 10)
          );
          setNextCamapaignStep(nextPageCount);
        }
      }
    }
    setCampaignsLoader(false);
  };

  const callGetLtWeeklyThemeAPI = async (pagN) => {
    const { value } = await props.getLtWeeklyTheme({ page: pagN, count: 100 });
    if (value?.Success) {
      setLtWeeklyThemeData(value?.Result?.Data);
      let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 100));
      setLtWeeklyNextStep(nextPageCount);
      let clearLoaderState = setInterval(() => {
        setLoader(false);
        clearInterval(clearLoaderState);
      }, 1000);
      // setLoader(false);
    }
  };

  const checkTodayLtWeekly = async () => {
    let date = new Date();
    date = moment(date, "MM DD YYYY").format("MM-DD-YYYY");
    const { value } = await props.getLtWeeklyItems({ date });
    if (value?.Success) {
      if (value?.Result?.Data) {
        setIsExist(true);
      } else {
        setIsExist(false);
      }
    } else {
      setIsExist(false);
    }
  };

  const callTagsApi = async () => {
    const { value } = await props.getTags();
    setTagsList([...value]);
    let stINt = setInterval(()=>{
    setTagsLoader(false);
    clearInterval(stINt)
  },3000)
  };

  useEffect(() => {
    if (location.pathname === "/iim-bulk-tags") {
      changeTab("news");
      changeSubTab("iim-bulk-tags");
    } else if (location.pathname === "/iim-weeklies") {
      // setLoader(false);
      changeTab("news");
      changeSubTab("iim-weeklies");
    } else if (location.pathname.includes("/iim-analytics")) {
      // changeTab("news");

      changeSubTab("iim-weeklies-analytics");
      // if (location.search.includes("campaign=Live")) {
      //   changeCampaignTab("Live");
      // } else if (location.search.includes("campaign=Test")) {
      //   changeCampaignTab("Test");
      // }
    } else if (location.pathname.includes("/free-form-emails")) {
      setCampaignsLoader(true);
      changeTab("free-form-emails");
      if (location.search.includes("campaign=Live")) {
        changeFreeFormCampaignTab("Live");
      } else if (location.search.includes("campaign=Test")) {
        changeFreeFormCampaignTab("Test");
      } else {
        changeFreeFormCampaignTab("Live");
      }
    } else if (location.pathname === "/iim-tags") {
      setTagsLoader(true);
      callTagsApi();
      changeTab("news");
      changeSubTab("tags");
    } else if (location.pathname === "/mailing-list") {
      changeTab("mailList");
    } else if (location.pathname === "/capital-markets") {
      changeTab("campaigns");
      changeSubTab("capital-markets");
    }

    if (!isCalled) {
      setIsCalled(true);
      callverifyTokenAPi()
      callApi();
      callMeApi();
      callCuratedTagApi();
      callGetLtWeeklyThemeAPI(LTWeeklyThemePage);
      getLtWeeklyThemeCategoriesApi();
      checkTodayLtWeekly();
    }
  }, [selectedCategories, selectedTags, loader, LTWeeklyDate]);


 const callverifyTokenAPi= async()=>{
    // const payload=
    // {token:localStorage.getItem("Authorization")}
    
const { value } = await props.ValidateToken()
console.log(value,'value')
// if(!value.Success){
//   console.log("here")
//   localStorage.removeItem("Authorization")
//   navigate("/login")
// }
  }
  // Select Start
  const animatedComponents = makeAnimated();
  // Slect End
  const closeModal = () => {
    setMailModalIsOpen(false);
    setDeletedRecord({});
    setDeleteModal(false);
    setEditMailOpen(false);
    setIsOpen(false);
  };
  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  function primaryModalClose() {
    setErrorBulktag("");
    setBulkPrimaryTag({});
    setPrimaryIsOpen(false);
  }

  function secondaryModalClose() {
    setErrorBulktag("");
    setBulkSecondayTag({});
    setSecondaryIsOpen(false);
  }

  function mailModalClose() {
    setMailModalIsOpen(false);
  }

  const uploadList = async () => {
    if (mailName?.trim()) {
      setIsLoading(true);
      const { value } = await props.updateMailingList({
        MailingDate: mailName?.trim(),
        Id: 0,
        MailUserList: data,
        IsLive:isLive
      });
      if (value?.Success) {
        setMailName("");
        setNameError("");
        setData([]);
        setCancel(false);
        setIsLoading(false);
        mailModalClose();
        setLoader(true);
        CallApiForGetMailingList(mailingListPage);
      } else {
        setNameError("Opp's, Already mail name exists");
        setIsLoading(false);
      }
    } else {
      if (!mailName?.trim()) {
        setNameError("Please enter Mail list name.");
      }
    }
  };

  const handleEmails = async (emails, key) => {
    setEmailError("");
    let finalEmails = [];
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (mailformat.test(emails[emails.length - 1])) {
      let emailArray = emails.map((email) => {
        if (email !== " " && email !== "" && !finalEmails.includes(email)) {
          finalEmails.push(email);
        }
      });
      await Promise.all(emailArray);
      initialState["emails"] = finalEmails;
      setInitialState(initialState);
      setSelectedEmails(finalEmails);
      emails.push(<Option key={emails}>{emails}</Option>);
    } else {
      if (emails.length === 0) {
        setSelectedEmails([]);
        emails = [];
      } else {
        setEmailError("Please enter valid email.");
      }
    }
  };

  const sendOtherNewsEmail = async () => {
    if (
      selectedEmails.length > 0 &&
      (finalCategories.length > 0 || finalTags.length)
    ) {
      setIsLoading(true);
      let categoryString = finalCategories.join("|");
      let curatedString = finalTags.join("|");
      let emailString = selectedEmails.join("|");

      let date = LTWeeklyDate.replaceAll("Week ending ", "");
      let endDate = new Date(date);
      let startDate = new Date(date);
      endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
      let lastSaturday = new Date(
        new Date(date).setDate(
          new Date(date).getDate() -
            (new Date(date).getDay() == 0 ? 7 : new Date(date).getDay() + 1)
        )
      );
      startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
      let payload = {
        StartDate: startDate,
        EndDate: endDate,
        Categories: categoryString,
        Emails: emailString,
        Curated: curatedString,
      };
      const { value } = await props.sendMailOtherNews(payload);
      if (value.Success) {
        setCategoriesList([]);
        setCuratedArray([]);
        setSelectedTags([]);
        setSelectedEmails([]);
        setIsLoading(false);
        setFinalCategories([]);
        setFinalTags([]);
        setIsOpen(false);
        setEmailError("");
        openNotificationWithIcon("success", "Sent Email successfully.");
      }
    } else {
      if (!finalCategories.length && !finalTags.length) {
        setEmailError("Please select at least one category.");
      } else {
        setEmailError("Please enter email.");
      }
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };

  const callCategoriesByDate = async (date) => {
    // setSelectedCategories([]);
    date = date?.replace("Week ending ", "");
    let endDate = new Date(date);
    let startDate = new Date(date);
    endDate = moment(endDate, "MM DD YYYY").format("YYYY-MM-DD");
    let lastSaturday = new Date(
      new Date(date).setDate(
        new Date(date).getDate() -
          (new Date(date).getDay() == 0 ? 7 : new Date(date).getDay() + 1)
      )
    );
    startDate = moment(lastSaturday, "MM DD YYYY").format("YYYY-MM-DD");
    let dates = {
      startDate: startDate,
      endDate: endDate,
    };
    const { value } = await props.getCategoriesByDate(dates);
    if (value) {
      setCategoriesList(value?.Result?.CategoriesList);
      let selectCatoriesList = [
        "Artificial Intelligence/Machine Learning",
        "Broadcast/Cable Networks",
        "Cable/Pay-TV/Wireless",
        "Cloud/Datacenters/IT Infrastructure",
        "eCommerce/Social Commerce/Retail",
        "Handheld Devices/Connected Home/Accessories",
        "Macro Updates",
        "Other (Technology)",
        "Regulatory",
        "Satellite/Space",
        "Social/Digital Media",
        "Software",
        "Tech Hardware",
        "Towers/Fiber",
        "Video Streaming",
      ];
      let list = [];
      setSelectedCategories([...selectedCategories]);
      setFinalCategories(value?.Result?.CategoriesList);
      setCuratedArray(value?.Result?.CuratedList);
      let clearLoaderState = setInterval(() => {
        setCategoryLoader(false);
        clearInterval(clearLoaderState);
      }, 700);
    }
  };

  const handleLTWeeklyDate = (LTWeeklyDate) => {
    setCategoryLoader(true);
    setLTweeklyDate(LTWeeklyDate);
    callCategoriesByDate(LTWeeklyDate);
  };

  const handlePrimaryTag = async () => {
    if (!isEmpty(bulkPrimaryTag)) {
      setTagLoader(true);
      let payloadList = [];
      let prm = selectedthemes?.map(async (item, index) => {
        let key = null;
        if (!item.SecondaryTag?.includes(bulkPrimaryTag?.value?.toString())) {
          key = bulkPrimaryTag?.value?.toString();
          payloadList.push({
            ThemeId: item.Id,
            SecondaryTagId: null,
            PrimaryTagId: key,
          });
        } else {
          key = bulkPrimaryTag?.value?.toString();
          let secondaryTagId =
            item.SecondaryTag?.split("|")?.length > 1
              ? item.SecondaryTag?.replace(`${key}|`, "")
              : item.SecondaryTag?.replace(`${key}`, "");
          let secTag = [];
          let prms = secondaryTagId?.split("|")?.map((sec) => {
            if (sec !== "" && sec?.toString() !== key) {
              secTag.push(sec);
            }
          });
          await Promise.all(prms);
          payloadList.push({
            ThemeId: item.Id,
            SecondaryTagId: secTag,
            PrimaryTagId: key,
          });
        }
      });
      await Promise.all(prm);
      const { value } = await props.updateTags(payloadList);
      if (value?.success) {
        setTagLoader(false);
        openNotificationWithIcon(
          "success",
          "Updated Primary Tag successfully."
        );
        setSelectedThemes([]);
        setLoader(true);
        setAllCheckedTheme(true);
        setIsSaveLoading(false);
        setIsChanged(false);
        saveDataList([]);
        setBulkPrimaryTag({});
        setPrimaryIsOpen(false);
        callGetLtWeeklyThemeAPI(LTWeeklyThemePage);
      }
    } else {
      setErrorBulktag("Please select at least one category");
    }
  };

  const getModifySecondayTag = (value) => {
    const val = value?.split("|");
    let valList = [];
    let prm = val?.map((e, index) => {
      let isChecked = secondaryTag.filter((obj) => {
        return obj.value?.toString() === e;
      });
      if (isChecked.length > 0) {
        isChecked[0].isFixed = true;
        valList.push(isChecked[0]);
      }
    });
    if (val?.length > 0) Promise.all(prm);
    valList = sortBy(valList, "label");
    // setDefaultSecondaryTags(valList)
    return valList;
  };

  const handleSecondaryTag = async () => {
    if (bulkSecondaryTag?.length > 0) {
      setErrorBulktag("");
      setTagLoader(true);
      let payloadList = [];
      let prm = selectedthemes?.map(async (item, index) => {
        let secTag = item?.SecondaryTag?.split("|");
        if (secTag === undefined) {
          secTag = [];
        }
        let prmtag = bulkSecondaryTag?.map((data) => {
          if (
            !item?.SecondaryTag?.includes(data?.value?.toString()) &&
            !item.PrimaryTag?.includes(data?.value?.toString())
          ) {
            secTag.push(data?.value?.toString());
          }
        });
        await Promise.all(prmtag);
        payloadList.push({
          ThemeId: item.Id,
          SecondaryTagId: secTag,
          PrimaryTagId: null,
        });
      });
      await Promise.all(prm);
      const { value } = await props.updateTags(payloadList);
      if (value?.success) {
        setTagLoader(false);
        openNotificationWithIcon(
          "success",
          "Updated Secondary Tag successfully."
        );
        setIsSaveLoading(false);
        setIsChanged(false);
        saveDataList([]);
        setAllCheckedTheme(true);
        setSelectedThemes([]);
        setBulkSecondayTag({});
        setPrimaryIsOpen(false);
        setLoader(true);
        setSecondaryIsOpen(false);
        callGetLtWeeklyThemeAPI(LTWeeklyThemePage);
      }
    } else {
      setErrorBulktag("Please select at least one category");
    }
  };

  const handleMailListName = (value) => {
    setNameError("");
    mailingData["MailingDate"] = value;
    setMailingData({ MailingDate: value, ...mailingData });
  };

  const uploadListName = async () => {
    if (mailingData?.MailingDate?.trim() !== "") {
      setIsLoading(true);
      const { value } = await props.updateMailingListName(mailingData);
      if (value.Success) {
        setMailingData({});
        setEditMailOpen(false);
        setIsLoading(false);
        CallApiForGetMailingList(mailingListPage);
        openNotificationWithIcon(
          "success",
          "Update Mailing Name successfully."
        );
      } else {
        setIsLoading(false);
        setNameError(value.Message);
      }
    } else {
      setNameError("Please Enter List Name");
    }
  };

  const handleCategories = (category) => {
    setCategoriesList([...categoriesList]);
    setLoader(true);
    let newArray = selectedCategories;
    if (newArray.includes(category)) {
      const index = newArray.indexOf(category);
      if (index > -1) {
        newArray.splice(index, 1);
      }
    } else {
      newArray.push(category);
    }
    setSelectedCategories([...newArray]);
    setFinalCategories([...newArray]);
    setLoader(false);
  };

  const handleCuratedTags = (curatedTag) => {
    setLoader(true);
    let newArray = selectedTags;
    if (newArray.includes(curatedTag)) {
      const index = newArray.indexOf(curatedTag);
      if (index > -1) {
        newArray.splice(index, 1);
      }
    } else {
      newArray.push(curatedTag);
    }
    setFinalTags([...newArray]);
    setLoader(false);
  };

  const getThemeTitle = (title) => {
    if (title.includes("<span")) {
      title = title.split("<span>")[1];
      title = title.split("</span>")[0];
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          if (title.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const saveData = async () => {
    setIsSaveLoading(true);
    for (var i = 0; i < dataList.length; i++) {
      let pay = [dataList[i]];
      const { value } = await props.updateTags(pay);
      if (dataList.length - 1 === i) {
        if (value.success) {
          setIsSaveLoading(false);
          setIsChanged(false);
          saveDataList([]);
          openNotificationWithIcon("success", "save bulk tags successfully");
        }
      }
    }
  };

  const handlePrimaryThemeCategories = async (i, val) => {
    setErrorBulktag("");
    setIsChanged(true);
    let singleThemeData = lTWeeklyThemeData[i];
    singleThemeData.PrimaryTag = val?.toString();
    lTWeeklyThemeData[i] = singleThemeData;
    setLtWeeklyThemeData([...lTWeeklyThemeData]);
    let payloadList = [];
    payloadList.push({
      ThemeId: singleThemeData?.Id,
      SecondaryTagId: null,
      PrimaryTagId: val?.toString(),
    });
    let arr = dataList;
    arr.push({
      ThemeId: singleThemeData?.Id,
      SecondaryTagId: null,
      PrimaryTagId: val?.toString(),
    });
    saveDataList([...arr]);
    // await props.updateTags(payloadList);
  };

  const ltWeeklyDate = (date) => {
    date = date?.replace("Week ending ", "");
    date = new Date(date);
    date = moment(date, "MM DD YYYY").format("MMMM-DD-YYYY");
    return date;
  };

  const handleSecondaryThemeCategories = async (i, val) => {
    setErrorBulktag("");
    setIsChanged(true);
    let strtag = [];
    let stag = val?.map((data) => {
      strtag.push(data?.value?.toString());
    });
    await Promise.all(stag);
    strtag = strtag?.join("|");
    let singleThemeData = lTWeeklyThemeData[i];
    singleThemeData.SecondaryTag = strtag;
    lTWeeklyThemeData[i] = singleThemeData;
    setLtWeeklyThemeData([...lTWeeklyThemeData]);
    let payloadList = [];
    payloadList.push({
      ThemeId: singleThemeData?.Id,
      SecondaryTagId: strtag !== "" ? strtag?.split("|") : [],
      PrimaryTagId: null,
    });
    let arr = dataList;
    arr.push({
      ThemeId: singleThemeData?.Id,
      SecondaryTagId: strtag !== "" ? strtag?.split("|") : [],
      PrimaryTagId: null,
    });
    saveDataList([...arr]);
    // await props.updateTags(payloadList);
  };

  const onThemeCheck = async (theme, key = "single") => {
    if (key === "single") {
      const found = selectedthemes?.findIndex((el) => el.Id === theme.Id);
      if (found === -1) {
        setSelectedThemes([...selectedthemes, theme]);
        if (selectedthemes?.length + 1 === lTWeeklyThemeData.length) {
          setAllCheckedTheme(false);
        }
      } else {
        selectedthemes.splice(found, 1);
        setAllCheckedTheme(true);
        setSelectedThemes([...selectedthemes]);
      }
    } else {
      const markData = [];
      if (!allCheckedTheme) {
        setAllCheckedTheme(true);
        setSelectedThemes([]);
      } else {
        let themeData = lTWeeklyThemeData?.map((theme, index) => {
          markData.push(theme);
        });
        await Promise.all(themeData);
        setSelectedThemes(markData);
        setAllCheckedTheme(false);
      }
    }
  };

  const getModifiedSecondaryTag = (data, categories) => {
    let valuList = [];
    let prm = categories?.map((cat) => {
      if (data?.PrimaryTag?.toString() != cat?.value?.toString()) {
        valuList.push({
          color: "#00B8D9",
          isFixed: data?.SecondaryTag?.includes(cat?.value) ? true : false,
          label: cat?.label,
          value: cat?.value,
        });
      }
    });
    return valuList?.sort((a, b) => a.label.localeCompare(b.label));
  };

  const removeMailingList = async () => {
  
    setIsLoading(true);
    let payload = {
      UserListIds: "",
      MailingListId: deletedRecord?.Id.toString()
    };

    const { value } = await props.removeMailingList(payload);
    if (value?.Success) {
      // setMailingListData([])
      CallApiForGetMailingList(mailingListPage);
      closeModal();
      setDeletedRecord({});
      setIsLoading(false);
    } else {
      closeModal();
      setDeletedRecord({});
      setIsLoading(false);
    }
  };

  const handleMailName = (name) => {
    setNameError("");
    setMailName(name);
  };
  const changeSubTab = (stab) => {
    if (tab === "news" && stab === "iim-weeklies") {
      navigate("/iim-weeklies");
    } else if (tab === "news" && stab === "tags") {
      setTagsLoader(true);
      callTagsApi();
      callHeaderTagApi()
      if(window.location.pathname.includes("/iim-tags")){

      }else{
      navigate("/iim-tags")
      }
    } else if (tab === "news" && stab === "iim-weeklies-analytics") {
      setCampaignsLoader(true);
      if(location?.search?.includes("campaign=Test")){
        getAllCampaigns("Test");
        setCampaignType("Test")
      }else if(location?.search?.includes("campaign=Live")){
        getAllCampaigns("Live");
        setCampaignType("Live")
      }else{
        setCampaignType("Live")
        getAllCampaigns("Live");
      }
      setCampaignList([])

    } else if (tab === "campaigns" && stab === "capital-markets") {
      navigate("/capital-markets");
    } else if (tab === "campaigns" && stab === "capital-markets-analytics") {
      navigate("/capital-markets-analytics");
    } else if (tab === "news" && stab === "iim-bulk-tags") {
      navigate("/iim-bulk-tags");
    }
    setSubTab(stab);
  };
  const onLiveListChecked = (val) => {
    setIsLive(val);
  };

  const exportMailingList = async (id,users,i) => {
    let data=isexportLoader
    data[i]=true
    setExportLoader([...data])
    if(users==0){
      data[i]=false
      setExportLoader([...data])
      openNotificationWithIcon("success", "No users found please add users first");
      return
    }
    const { value } = await props.getMailingListById({ Id: id, Page: 1, Count: users });
    
    if (value?.Result?.MailingListUSers?.length > 0) {
      
      const mailingListUsers = value?.Result?.MailingListUSers.map((item,index) => ({
        SrNo:index+1,
        Email: item.Email,
        FullName: item.FullName,
        FirstName: item.FirstName,
        LastName: item.LastName,
      }));
  
      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      const csvData = new Blob([convertToCSV(mailingListUsers)], { type: 'text/csv' });
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', `MailingList_${id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } 
    data[i]=false
      setExportLoader([...data])
  };
  
  // Function to convert JSON to CSV string
  const convertToCSV = (array) => {
    const headers = ["SrNo","Email", "Full Name", "First Name", "Last Name"];
    const rows = array.map(obj => [`="${obj.SrNo}"`, obj.Email, obj.FullName, obj.FirstName, obj.LastName].join(","));
    return [headers.join(","), ...rows].join("\n");
  };

  const closeEditTagModal = () => {
    setNameError("");
    setOldTagName("")
    setTagName({})
    setNewTagName("")
    seteditTagNameModal(false);
  };
  const openEditTagModal =(value,i)=>{
    setTagName({value:value,i:i})
    setOldTagName(value)
    seteditTagNameModal(true)
  }
  const handleUpdateTagName = (value,i)=>{
  // tagsList[i].Tag = value
  setTagName({value:value,i:i})
  setNewTagName(value)
  // setTagsList([...tagsList])
  setNameError("")
  }
  const updateTagName = async()=>{
    if(tagName.value===""||tagName.value==="null"){
      setNameError("Please fill proper tag Name, it can not be blank")
      return
    }
    const isDuplicate = tagsList.some(
      (tag, index) => tag.Tag === newTagName && index !== tagName.i
    );
  
    if (isDuplicate) {
      setNameError("Tag name already exists, please choose a different name.");
      return;
    }
    setIsLoading(true)
   const payload = {
      CategoryTitleOld:oldTagName,
      CategoryTitleNew:newTagName
     }
const { value } = await props.updateTagsName(payload)
if(value.Success){
  tagsList[tagName.i].Tag = newTagName
  setTagsList([...tagsList])
  setIsLoading(false)
  setOldTagName("")
  setTagName({})
  setNewTagName("")
  seteditTagNameModal(false)
  setNameError("")
  openNotificationWithIcon("success", "Tag name updated successfully.");
  window.location.reload(true)
}else{
  setIsLoading(false)
  seteditTagNameModal(false)
  setNameError("")
}
  }
  const openCreateTagModal=()=>{
    setCreateTagModalIsOpen(true)
  }
  const closeCreateTagModal=()=>{
    setCreateTagModalIsOpen(false)
    setHeaderCategoryId("")
    setCreateTag("")
    setselectedHeaderCategory("")
    setNameError("")
    setTagNameError("")
  }
  const handleCreateTagName =(value)=>{
     setCreateTag(value)
     setTagNameError("")
  }
  
  const handleHeadertagName = (selectedCategory) => {
    if (selectedCategory&&selectedCategory!==undefined&&selectedCategory!=="") {
      setselectedHeaderCategory(selectedCategory.Title); 
      setHeaderCategoryId(selectedCategory.Id);
      setNameError("")
    }else {
      setselectedHeaderCategory("")
      setHeaderCategoryId("")
      // setNameError("Please select any one header category")
    }
  };
  const saveNewTag=async()=>{
    let hasError = false;
    if(!createTag || createTag==""){
      setTagNameError("Please enter tag name")
      hasError = true;
    }
    
    if(!selectedHeaderCategory||selectedHeaderCategory==""||selectedHeaderCategory==undefined){
    setNameError("Please select any one header category")
    hasError = true;
    }
    if(hasError){
      return
    }
    const existingCategory = newsCategoryData.find(
      (cat) => cat.Name === createTag || cat.Title === createTag
    );
    
    if (existingCategory) {
      if (existingCategory.ShowInWebsite) {
        setTagNameError("Category already exists and is already shown on the website.");
        return;
      } 
      else {
        const updatePayload = {
          NewsCategoryHeader_Id: headerCategoryId,
          Name: existingCategory.Name,
          Title: existingCategory.Title,
          ShowInApp: true,
          ShowInWebsite: true,
          IsNews: true
        };
        setIsLoading(true)
        const { value } = await props.UpdateNewsCategoryTagsVisibility(updatePayload)
        if (value.success) {
          setIsLoading(false)
          closeCreateTagModal()
          openNotificationWithIcon("success", "Tag created successfully.");
          window.location.reload(true)
        }else{
          setIsLoading(false)
          closeCreateTagModal()
        }
    }
  }else{
   const  payload={
      NewsCategoryHeader_Id: headerCategoryId,
      Name: createTag,
      Title: createTag,
      ShowInApp: true,
      ShowInWebsite: true,
      IsNews: true
    }
    setIsLoading(true)
    const { value } = await props.CreateTagsName(payload)
    if(value.success){
      setIsLoading(false)
      closeCreateTagModal()
      openNotificationWithIcon("success", "Tag created successfully.");
      window.location.reload(true)
    }else{
      setIsLoading(false)
      closeCreateTagModal()
    }
    
  }
}

  const closeDeleteTagModal = () => {
    setOldTagName("")
    setTagName({})
    setNewTagName("")
    setDeleteTagId("")
    setdeleteTagNameModal(false);
  };
  const openDeleteTagModal =(tagName)=>{
    const existingCategory = newsCategoryData.find(category => category.Title === tagName);
    if (existingCategory){
        const tagId = existingCategory.Id
        setDeleteTagId(tagId)
  }
    setdeleteTagNameModal(true)
  }
  const deleteTagName = async()=>{
      const payload ={
          id:deleteTagId
    }
    setIsLoading(true)
    const { value }= await props.removeThemeandOtherNewsTagById(payload)
    if (value.success){
      setIsLoading(false)
      closeDeleteTagModal()
      setDeleteTagId()
      openNotificationWithIcon("success", "Tag deleted sucessfully")
      window.location.reload(true)
    }else{
      setIsLoading(false)
      closeDeleteTagModal()
      setDeleteTagId()
    }
}
const ExportWebAccessList =async()=>{
  setWebAccessLoader(true)
  const { value } = await props.GetWebAccessList();
  
  if (value?.Result?.length > 0) {
    const mailingListUsers = value?.Result?.map((item, index) => {
      const [City, Country] = item.Location ? item.Location.split(",").map(part => part.trim()) : ["", ""]
      return{
      SrNo:index+1,
      FirstName: item.FirstName ||"",
      LastName: item.LastName || "",
      CompanyName:item.Company || "",
      jobTitle:item.JobFunction || "",
      Email: item.Email || "",
      City:City || "",
      Country:Country || ""
      }
    });

    // Convert data to CSV
    const csvContent = convertToCSVNew(mailingListUsers);

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    // Trigger the download programmatically
    const fileName = 'WebAccessList.csv';
    const tempElement = document.createElement('div'); // Dummy element
    tempElement.onclick = () => {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url); // Free up memory
    };
    tempElement.click();
    
  } else{
    openNotificationWithIcon("error","No users found")
    return
  }
  setWebAccessLoader(false)

}
const convertToCSVNew = (array) => {
  const headers = ["SrNo", "First Name", "Last Name", "Company Name", "Job Title", "Email", "City", "Coutry"]
  const rows = array.map(obj => [`="${obj.SrNo}"`, 
    `"${obj.FirstName}"`, `"${obj.LastName}"`, `"${obj.CompanyName}"`, `"${obj.jobTitle}"`, `"${obj.Email}"`, `"${obj.City}"`,`"${obj.Country}"`].join(","));
  return [headers.join(","), ...rows].join("\n");
};
  return (
    <>
      <main className="main home__thmes-wrapper" role="header">
        <div className="main__header">
          <TopNavigation userData={userData} {...props} />
        </div>
        <div className="tabs__links tabs__links--space">
          {userDetails?.IIMWeeklies && (
            <Link
              className={tab === "news" ? "tabs__link active" : "tabs__link"}
              to="/iim-weeklies"
              onClick={() => changeTab("news")}
            >
              IIM Weeklies
            </Link>
            // <button
            //   className={tab === 'news' ? 'tabs__link active' : 'tabs__link'}
            //   onClick={() => changeTab('news')}
            // >
            //   IIM Weeklies
            // </button>
          )}
          <Link
            className={
              tab === "free-form-emails" ? "tabs__link active" : "tabs__link"
            }
            to="/free-form-emails?campaign=Live"
            onClick={() => {
              setCampaignsLoader(true)
              setCampaignList([])
              setCampaignType("")
              setFreeFormCampaignType("Live")
              changeCampaignTab("FreeFormLive")
              changeTab("free-form-emails") 
            }}
          >
            Free Form Emails
          </Link>
          {/* <button
            className={tab === 'free-form-email' ? 'tabs__link active' : 'tabs__link'}
            onClick={() => changeTab('free-form-email')}
          >
            Free Form Emails
          </button> */}
          <Link
            className={tab === "mailList" ? "tabs__link active" : "tabs__link"}
            to="/mailing-list"
            onClick={() => {
              setFreeFormCampaignType("Live");
              changeTab("mailList");
            }}
          >
            Mailing List
          </Link>
        </div>
        {tab === "theme" && (
          <div className="custom__container">
            <div className="all__slect  sticky-header-container">
              <label className="custom__checkbox">
                <input
                  type="checkbox"
                  className="custom__checkbox--input"
                  checked={!allCheckedTheme}
                  onChange={(e) => onThemeCheck({}, "All")}
                />
                <span className="custom__checkbox--check"></span>
              </label>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={() => setPrimaryIsOpen(!primaryModalIsOpen)}
              >
                Bulk Add Primary Tag
              </button>
              <button
                className="btn btn__purple"
                disabled={!selectedthemes.length}
                onClick={() => setSecondaryIsOpen(!secondaryModalIsOpen)}
              >
                Bulk Add Secondary Tag
              </button>
            </div>
            <div className="table-responsive table__responsive--custom">
              <table className="table table__custom">
                <thead>
                  <tr>
                    <th className="table__selection--column">LT Weekly Date</th>
                    <th>Theme #</th>
                    <th>Title</th>
                    <th>Primary Tag</th>
                    <th>Secondary Tag</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  ) : (
                    lTWeeklyThemeData?.length > 0 &&
                    lTWeeklyThemeData?.map((item, i) => {
                      return (
                        <tr>
                          <td className="table__status">
                            <label className="custom__checkbox">
                              <input
                                name="themes"
                                value={i}
                                checked={
                                  selectedthemes
                                    ?.map((theme) => theme.Id)
                                    ?.indexOf(item.Id) !== -1
                                }
                                onChange={() => onThemeCheck(item)}
                                type="checkbox"
                                className="custom__checkbox--input"
                              />
                              <span className="custom__checkbox--check"></span>
                              {item.WeeklyDate}
                            </label>
                          </td>
                          <td className="table__status">{item.ItemName}</td>
                          <td className="table__status">
                            <Tooltip
                              title={getThemeTitle(item.ShortDescription)}
                              color={"#4D1B6C"}
                            >
                              <span className="text-block">
                                {getThemeTitle(item.ShortDescription)}
                              </span>
                            </Tooltip>
                          </td>
                          <td className="table__status">
                            <div className="form-group">
                              <select
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  paddingRight: "40px",
                                }}
                                className="form-control"
                                value={parseInt(item.PrimaryTag)}
                                onChange={(e) => {
                                  handlePrimaryThemeCategories(
                                    i,
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="0">Select</option>
                                {themeCategoriesData
                                  ?.sort((a, b) =>
                                    a.Title.localeCompare(b.Title)
                                  )
                                  ?.map((it) => {
                                    if (
                                      !item.SecondaryTag?.split("|")?.find(
                                        (is) => is.Id === it.Id
                                      )
                                    ) {
                                      return (
                                        <option value={it.Id}>
                                          {it.Title}
                                        </option>
                                      );
                                    } else {
                                      console.log(
                                        item.SecondaryTag,
                                        "item.SecondaryTag"
                                      );
                                    }
                                  })}
                              </select>
                            </div>
                          </td>
                          <td style={{ maxWidth: "350px" }}>
                            <div className="tag__group">
                              <SelectReact
                                onChange={(e) => {
                                  handleSecondaryThemeCategories(i, e);
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={getModifySecondayTag(item.SecondaryTag)}
                                // defaultValue={getModifySecondayTag(item.SecondaryTag)}
                                isMulti
                                options={getModifiedSecondaryTag(
                                  item,
                                  secondaryTag
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {lTWeeklyThemeData?.length > 0 && (
              <div className="table__pagination">
                <div className="table__pagination--results">
                  {size(lTWeeklyThemeData)} results
                </div>
                <div className="table__pagination--nav">
                  <>
                    {LTWeeklyThemePage - 1 >= 1 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--prev"
                        onClick={() => pageChangeForLtWeekly("previous")}
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled
                        className="btn btn__default table__pagination--prev disabled"
                      >
                        Previous
                      </button>
                    )}
                  </>
                  <>
                    {ltWeeklyNextStep - LTWeeklyThemePage > 0 ? (
                      <button
                        type="button"
                        className="btn btn__default table__pagination--next"
                        onClick={() => pageChangeForLtWeekly("next")}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled
                        className="btn btn__default table__pagination--next disabled"
                      >
                        Next
                      </button>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>
        )}
        {tab === "news" && (
          <>
            {userDetails.IIMWeeklies && (
              <div className="page__header--left">
                <div className="inner-tabs-container">
                  {/* <button
                    className={
                      subTab === 'iim-weeklies'
                        ? 'tabs__link active'
                        : 'tabs__link'
                    }
                    changes
                    onClick={() => changeSubTab('iim-weeklies')}
                  >
                    IIM Weeklies
                  </button> */}
                  <Link
                    to="/iim-weeklies"
                    onClick={() => changeSubTab("iim-weeklies")}
                  >
                    <button
                      className={
                        subTab === "iim-weeklies"
                          ? "tabs__link active"
                          : "tabs__link"
                      }
                    >
                      IIM Weeklies
                    </button>
                  </Link>
                  {"  "}
                  <Link
                    to="/iim-analytics?campaign=Live"
                    onClick={() => 
                      {
                        setCampaignType("")

                        changeCampaignTab("Live")

                        changeSubTab("iim-weeklies-analytics")
                      }
                    }
                    >
                    <button
                      className={
                        subTab === "iim-weeklies-analytics"
                          ? "tabs__link active"
                          : "tabs__link"
                      }
                      // changes
                      // onClick={() => changeSubTab('iim-weeklies-analytics')}
                    >
                      IIM Weeklies Analytics
                    </button>
                  </Link>
                  {"  "}
                  <Link
                    to="/iim-bulk-tags"
                    onClick={() => changeSubTab("iim-bulk-tags")}
                  >
                    <button
                      className={
                        subTab === "iim-bulk-tags"
                          ? "tabs__link active"
                          : "tabs__link"
                      }
                      // changes
                      // onClick={() => changeSubTab('iim-bulk-tags')}
                    >
                      IIM Theme Tagging
                    </button>
                  </Link>
                  <Link to="/iim-tags" onClick={() => changeSubTab("tags")}>
                    <button
                      className={
                        subTab === "tags" ? "tabs__link active" : "tabs__link"
                      }
                      // changes
                      // onClick={() => changeSubTab('tags')}
                    >
                      Tags
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {subTab === "iim-weeklies" ? (
              <>
                <div className="page__header">
                  <div className="page__header--container">
                    <div className="page__header--row align-items-center">
                      <div className="page__header--left">
                        <h1 className="page__header--title">IIM Weeklies</h1>
                      </div>
                      <div className="page__header--right">
                       <button
                          onClick={() => {
                            ExportWebAccessList()
                          }}
                          type='button'
                          className='btn btn__purple ExportListLoader'
                        >
                        {webAccessLoader ? <Spin indicator={antIcon} className="customSize"/> : "Web Only Access"}
                        </button>
                        <button
                          onClick={() => {
                            setIsOpen(!modalIsOpen);
                            callCuratedTagApi();
                            handleLTWeeklyDate(newsData[0]?.LTWeeklyDate);
                          }}
                          type="button"
                          className="btn btn__purple"
                        >
                          Send Other News
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="custom__container">
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th>Lt Weekly Date</th>
                          <th>All Articles</th>
                          <th>Approved Articles</th>
                          <th>Saved Articles</th>
                          <th>Draft Articles</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 30, color: "#000" }}
                                spin
                              />
                            }
                            className="loader__full"
                          />
                        ) : (
                          newsData?.length > 0 &&
                          newsData?.map((news, i) => {
                            return (
                              <tr key={i}>
                                <td
                                  // onClick={() => {
                                  //   ltWeeklyDate(news.LTWeeklyDate);
                                  //   navigate(
                                  //     `/themes/${ltWeeklyDate(
                                  //       news.LTWeeklyDate
                                  //     )}`,
                                  //     {
                                  //       state: { date: news.LTWeeklyDate },
                                  //     }
                                  //   );
                                  // }}
                                  className="underline cursor"
                                >
                                  <Link
                                    to={`/themes/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}`}
                                    state={{ date: news.LTWeeklyDate }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {news.LTWeeklyDate}
                                  </Link>
                                </td>
                                <td
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/other-news/${ltWeeklyDate(
                                  //       news.LTWeeklyDate
                                  //     )}`,
                                  //     {
                                  //       state: {
                                  //         date: news.LTWeeklyDate,
                                  //         articleStatus: 'all',
                                  //       },
                                  //     }
                                  //   )
                                  // }
                                  className="underline cursor"
                                >
                                  <Link
                                    to={`/other-news/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}?articleStatus=all`}
                                    state={{
                                      date: news.LTWeeklyDate,
                                      articleStatus: "all",
                                    }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {news.TotalCount}
                                  </Link>
                                </td>
                                <td
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/other-news/${ltWeeklyDate(
                                  //       news.LTWeeklyDate
                                  //     )}`,
                                  //     {
                                  //       state: {
                                  //         date: news.LTWeeklyDate,
                                  //         articleStatus: 'completed',
                                  //       },
                                  //     }
                                  //   )
                                  // }
                                  className="underline cursor"
                                >
                                  <Link
                                    to={`/other-news/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}?articleStatus=completed`}
                                    state={{
                                      date: news.LTWeeklyDate,
                                      articleStatus: "completed",
                                    }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {news.Completed}
                                  </Link>
                                </td>
                                <td
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/other-news/${ltWeeklyDate(
                                  //       news.LTWeeklyDate
                                  //     )}`,
                                  //     {
                                  //       state: {
                                  //         date: news.LTWeeklyDate,
                                  //         articleStatus: 'saved',
                                  //       },
                                  //     }
                                  //   )
                                  // }
                                  className="underline cursor"
                                >
                                  <Link
                                    to={`/other-news/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}?articleStatus=saved`}
                                    state={{
                                      date: news.LTWeeklyDate,
                                      articleStatus: "saved",
                                    }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {news.Saved}
                                  </Link>
                                </td>
                                <td
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/other-news/${ltWeeklyDate(
                                  //       news.LTWeeklyDate
                                  //     )}`,
                                  //     {
                                  //       state: {
                                  //         date: news.LTWeeklyDate,
                                  //         articleStatus: 'draft',
                                  //       },
                                  //     }
                                  //   )
                                  // }
                                  className="underline cursor"
                                >
                                  <Link
                                    to={`/other-news/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}?articleStatus=draft`}
                                    state={{
                                      date: news.LTWeeklyDate,
                                      articleStatus: "draft",
                                    }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {news.Pending}
                                  </Link>
                                </td>
                                <td className="text-end">
                                  {/* <button
                                    onClick={() =>
                                      navigate(
                                        `/themes/${ltWeeklyDate(
                                          news.LTWeeklyDate
                                        )}`,
                                        {
                                          state: { date: news.LTWeeklyDate },
                                        }
                                      )
                                    }
                                    className='edit-btn'
                                  >
                                    Edit
                                  </button> */}

                                  <Link
                                    to={`/themes/${ltWeeklyDate(
                                      news.LTWeeklyDate
                                    )}`}
                                    state={{ date: news.LTWeeklyDate }}
                                    className="edit-btn underline"
                                    style={{ color: "inherit" }}
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  {newsData?.length > 0 && (
                    <div className="table__pagination">
                      <div className="table__pagination--results">
                        {size(newsData)} results
                      </div>
                      <div className="table__pagination--nav">
                        <>
                          {page - 1 >= 1 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev"
                              onClick={() => pageChange("previous")}
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--prev disabled"
                            >
                              Previous
                            </button>
                          )}
                        </>
                        <>
                          {nextStep - page > 0 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next"
                              onClick={() => pageChange("next")}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--next disabled"
                            >
                              Next
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : subTab === "iim-bulk-tags" ? (
              <>
                {/* <br/> */}
                <div className="custom__container">
                  <div className="all__slect sticky-header-container">
                    <label className="custom__checkbox">
                      <input
                        type="checkbox"
                        className="custom__checkbox--input"
                        checked={!allCheckedTheme}
                        onChange={(e) => onThemeCheck({}, "All")}
                      />
                      <span className="custom__checkbox--check"></span>
                    </label>
                    <button
                      className="btn btn__purple"
                      disabled={!selectedthemes.length}
                      onClick={() => setPrimaryIsOpen(!primaryModalIsOpen)}
                    >
                      Bulk Add Primary Tag
                    </button>
                    <button
                      className="btn btn__purple"
                      disabled={!selectedthemes.length}
                      onClick={() => setSecondaryIsOpen(!secondaryModalIsOpen)}
                    >
                      Bulk Add Secondary Tag
                    </button>
                    {isChanged && (
                      <button
                        className="btn btn__purple"
                        // disabled={!selectedthemes.length}
                        onClick={() => saveData()}
                      >
                        {isSaveLoading ? <Spin indicator={antIcon} /> : "Save"}
                      </button>
                    )}
                  </div>
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th className="table__selection--column">
                            LT Weekly Date
                          </th>
                          <th>Theme #</th>
                          <th>Title</th>
                          <th className="theme-img-width">Theme Img</th>
                          <th>Primary Tag</th>
                          <th>Secondary Tag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 30, color: "#000" }}
                                spin
                              />
                            }
                            className="loader__full"
                          />
                        ) : (
                          lTWeeklyThemeData?.length > 0 &&
                          lTWeeklyThemeData?.map((item, i) => {
                            return (
                              <tr>
                                <td className="table__status">
                                  <label className="custom__checkbox">
                                    <input
                                      name="themes"
                                      value={i}
                                      checked={
                                        selectedthemes
                                          ?.map((theme) => theme.Id)
                                          ?.indexOf(item.Id) !== -1
                                      }
                                      onChange={() => onThemeCheck(item)}
                                      type="checkbox"
                                      className="custom__checkbox--input"
                                    />
                                    <span className="custom__checkbox--check"></span>
                                    {item.WeeklyDate}
                                  </label>
                                </td>
                                <td className="table__status">
                                  {item.ItemName}
                                </td>
                                <td className="table__status">
                                  <Tooltip
                                    title={getThemeTitle(item.ShortDescription)}
                                    color={"#4D1B6C"}
                                  >
                                    <span className="text-block">
                                      {getThemeTitle(item.ShortDescription)}
                                    </span>
                                  </Tooltip>
                                </td>
                                <td className="table__status theme-img-width">
                                  <div className="form-group">
                                    <img
                                      src={item?.Photo}
                                      alt="theme img"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setImageIndex(i);
                                        setIsNextDisabled(false);
                                        setIsPrevDisabled(false);
                                        setImageModalOpen(true);
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="table__status">
                                  <div className="form-group">
                                    <select
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        paddingRight: "40px",
                                      }}
                                      className="form-control"
                                      value={parseInt(item.PrimaryTag)}
                                      onChange={(e) => {
                                        handlePrimaryThemeCategories(
                                          i,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="0">Select</option>
                                      {themeCategoriesData
                                        ?.sort((a, b) =>
                                          a.Title.localeCompare(b.Title)
                                        )
                                        .map((it) => {
                                          if (
                                            !item.SecondaryTag ||
                                            item.SecondaryTag?.split(
                                              "|"
                                            )?.findIndex(
                                              (is) => is === it.Id.toString()
                                            ) === -1
                                          ) {
                                            return (
                                              <option value={it.Id}>
                                                {it.Title}
                                              </option>
                                            );
                                          }
                                        })}
                                    </select>
                                  </div>
                                </td>
                                <td style={{ maxWidth: "350px" }}>
                                  <div className="tag__group">
                                    <SelectReact
                                      onChange={(e) => {
                                        handleSecondaryThemeCategories(i, e);
                                      }}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                      value={getModifySecondayTag(
                                        item.SecondaryTag
                                      )}
                                      // defaultValue={getModifySecondayTag(item.SecondaryTag)}
                                      isMulti
                                      options={getModifiedSecondaryTag(
                                        item,
                                        secondaryTag
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {lTWeeklyThemeData?.length > 0 && (
                    <div className="table__pagination">
                      <div className="table__pagination--results">
                        {size(lTWeeklyThemeData)} results
                      </div>
                      <div className="table__pagination--nav">
                        <>
                          {LTWeeklyThemePage - 1 >= 1 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev"
                              onClick={() => pageChangeForLtWeekly("previous")}
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--prev disabled"
                            >
                              Previous
                            </button>
                          )}
                        </>
                        <>
                          {ltWeeklyNextStep - LTWeeklyThemePage > 0 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next"
                              onClick={() => pageChangeForLtWeekly("next")}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--next disabled"
                            >
                              Next
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : subTab === "tags" ? (
              <>
                <div className="page__header">
                  <div className="page__header--container">
                    <div className="page__header--row align-items-center">
                      <div className="page__header--left">
                        <h1 className="page__header--title">Tags</h1>
                      </div>
                      <div className="page__header--right">
                      <button
                      onClick={() => {
                        openCreateTagModal();
                      }}
                      type="button"
                      className="btn btn__purple"
                    >
                      Create Tag
                    </button>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="custom__container">
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th>Tag Name</th>
                          <th>Number of themes</th>
                          <th>Number of articles</th>
                          <th className="text-center">Edit tag name</th>
                          <th className="text-center">Delete tag name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tagsLoader ? (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 30, color: "#000" }}
                                spin
                              />
                            }
                            className="loader__full"
                          />
                        ) : (
                          tagsList?.length > 0 &&
                          tagsList?.map((tag, i) => {
                            return (
                              <tr key={i}>
                                <td
                                // onClick={() =>
                                //   navigate(
                                //     `/iim-tags/${tag.Tag?.replaceAll(
                                //       ' ',
                                //       '_'
                                //     )?.replaceAll('/', '--')}/all`,
                                //     {
                                //       state: {  },
                                //     }
                                //   )
                                // }
                                // className='underline cursor'
                                >
                                  <Link
                                    to={`/iim-tags/${tag.Tag?.replaceAll(
                                      " ",
                                      "_"
                                    )?.replaceAll("/", "--")}/all`}
                                    state={{ data: tag }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {tag.Tag}
                                  </Link>
                                </td>
                                <td
                                // onClick={() =>
                                //   navigate(
                                //     `/iim-tags/${tag.Tag?.replaceAll(
                                //       ' ',
                                //       '_'
                                //     )?.replaceAll('/', '--')}/themes`,
                                //     {
                                //       state: { data: tag },
                                //     }
                                //   )
                                // }
                                // className='underline cursor'
                                >
                                  <Link
                                    to={`/iim-tags/${tag.Tag?.replaceAll(
                                      " ",
                                      "_"
                                    )?.replaceAll("/", "--")}/themes`}
                                    state={{ data: tag }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {parseInt(tag.Theme)?.toLocaleString(
                                      "en-US"
                                    )}
                                  </Link>
                                </td>
                                <td
                                // onClick={() =>
                                //   navigate(
                                //     `/iim-tags/${tag.Tag?.replaceAll(
                                //       ' ',
                                //       '_'
                                //     )?.replaceAll('/', '--')}/other-news`,
                                //     {
                                //       state: { data: tag },
                                //     }
                                //   )
                                // }
                                // className='underline cursor'
                                >
                                  <Link
                                    to={`/iim-tags/${tag.Tag?.replaceAll(
                                      " ",
                                      "_"
                                    )?.replaceAll("/", "--")}/other-news`}
                                    state={{ data: tag }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {parseInt(tag.Article)?.toLocaleString(
                                      "en-US"
                                    )}
                                  </Link>
                                </td>
                                <td className="text-center">
                                <Edit
                                className="cursor"
                                onClick={() => {
                                  openEditTagModal(tag.Tag, i);
                                }}
                              />
                                </td>
                                <td className="text-center">
                                <Delete 
                                className="cursor"
                                  onClick={() => {
                                    openDeleteTagModal(tag.Tag);
                                  }}
                                />
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {(tagsList?.length === 0 && !tagsLoader) && (
                    <div className="table__blank">
                      <div className="table__blank--text">No Data</div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="page__header">
                  <div className="page__header--container">
                    <div className="page__header--row align-items-center">
                      <div className="page__header--left">
                        <h1 className="page__header--title">Campaigns</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs__links tabs__links--space">
                  <Link
                    to="/iim-analytics?campaign=Live"
                    onClick={() => {
                      setCampaignList([]);
                      changeCampaignTab("Live");
                    }}
                    className={
                      campaignType === "Live"
                        ? "tabs__link active"
                        : "tabs__link"
                    }
                    // onClick={() => changeCampaignTab('Live')}
                  >
                    Live Campaigns
                  </Link>
                  <Link
                    to="/iim-analytics?campaign=Test"
                    onClick={() => {
                      setCampaignList([]);
                      changeCampaignTab("Test");
                    }}
                    className={
                      campaignType === "Test"
                        ? "tabs__link active"
                        : "tabs__link"
                    }
                    // changes
                    // onClick={() => changeCampaignTab('Test')}
                  >
                    Test Campaigns
                  </Link>
                </div>
                <div className="custom__container">
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>LT Weekly Date</th>
                          <th>Total Emails Sent</th>
                          <th>Total Not Sent</th>
                          <th>Total Unique Opens</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignsLoader ? (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 30, color: "#000" }}
                                spin
                              />
                            }
                            className="loader__full"
                          />
                        ) : (
                          campaignsList?.length > 0 &&
                          campaignsList?.map((cam, i) => {
                            return (
                              <tr key={i}>
                                <td
                                // onClick={() =>
                                //   navigate(`/campaign/overview/${cam.id}`, {
                                //     state: { data: cam },
                                //   })
                                // }
                                // className='underline cursor'
                                >
                                  {/* {cam.name
                                    .replaceAll('--live', '')
                                    .replaceAll('--test', '')} */}
                                  <Link
                                    to={`/campaign/overview/${cam.CampaignId}`}
                                    state={{ data: cam, pathname: "/iim-analytics", campaignType: campaignType }}
                                    className="underline cursor"
                                    style={{ color: "inherit" }}
                                  >
                                    {cam.CampaignName
                                    .replaceAll("PlainTextTest--FreeFormTest","")
                                    .replaceAll("PlainTextLive--FreeFormLive","")
                                      .replaceAll("--live", "")
                                      .replaceAll("--test", "")
                                      .replaceAll("--Live", "")
                                      .replaceAll("--Test", "")
                                      .replaceAll("FreeFormTest", "")
                                      .replaceAll("FreeFormLive", "")
                                      .replaceAll("---", "")
                                      .replaceAll("PlainTextLive", "")
                                      .replaceAll("PlainTextTest","")
                                      .replaceAll("-", "")
                                    }
                                  </Link>
                                </td>
                                {cam.Catagory !== "" ? (
                                  <td
                                  // onClick={() =>
                                  //   window.open(`/themes/${(cam.categories[0])?.replaceAll(" ","-")}`)
                                  // }
                                  >
                                    <Link
                                      target={"_blank"}
                                      to={`/themes/${cam.Category?.replaceAll(
                                        " ",
                                        "-"
                                      )}`}
                                      className="underline cursor theme-text-color"
                                    >
                                      {cam.Category}
                                    </Link>
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                {cam?.DeliveredCount ? (
                                  <>
                                    <td>
                                      {parseInt(
                                        cam?.DeliveredCount
                                      )?.toLocaleString("en-US")}
                                    </td>
                                    <td>
                                      {parseInt(
                                        cam?.NotDeliveredCount 
                                      )?.toLocaleString("en-US")}
                                    </td>
                                    <td>
                                      {parseInt(
                                        cam?.OpenedCount
                                      )?.toLocaleString("en-US")}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                  </>
                                )}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {(campaignsList?.length === 0 && !campaignsLoader)&&(
                    <div className="table__blank">
                      <div className="table__blank--text">No Data</div>
                    </div>
                  )}
                  {campaignsList?.length > 0 && (
                    <div className="table__pagination">
                      <div className="table__pagination--results">
                        {size(campaignsList)} results
                      </div>
                      <div className="table__pagination--nav">
                        <>
                          {campaignsListPage - 1 >= 1 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev"
                              onClick={() =>
                                pageChangeCampaignsList("previous")
                              }
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--prev disabled"
                            >
                              Previous
                            </button>
                          )}
                        </>
                        <>
                          {nextCamapaignStep - campaignsListPage > 0 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next"
                              onClick={() => pageChangeCampaignsList("next")}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--next disabled"
                            >
                              Next
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {tab === "free-form-emails" && (
          <>
            <div className="page__header">
              <div className="page__header--container">
                <div className="page__header--row align-items-center">
                  <div className="page__header--left">
                    <h1 className="page__header--title">Free Form Emails</h1>
                  </div>
                  <div className="page__header--right">
                    <Link
                      to="/send-email"
                      // onClick={() => changeFreeFormCampaignTab('Live')}
                      className="btn btn__purple"
                    >
                      Send Free Form Email
                    </Link>
                    {/* <button
                    onClick={() => {
                      navigate('/send-email?Id=')
                      // setIsOpen(!modalIsOpen);
                      // callCuratedTagApi();
                      // handleLTWeeklyDate(newsData[0]?.LTWeeklyDate);
                    }}
                    type='button'
                    className='btn btn__purple'
                  >
                    Send Free Form Email
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs__links tabs__links--space">
              <Link
                to="/free-form-emails?campaign=Live"
                onClick={() => {
                  setCampaignList([]);
                  changeFreeFormCampaignTab("Live");
                }}
                className={
                  freeFormCampaignType === "Live"
                    ? "tabs__link active"
                    : "tabs__link"
                }
                // onClick={() => changeCampaignTab('Live')}
              >
                Live Campaigns
              </Link>
              <Link
                to="/free-form-emails?campaign=Test"
                onClick={() => 
                  {
                   setCampaignList([])
                   changeFreeFormCampaignTab("Test")}}
                className={
                  freeFormCampaignType === "Test"
                    ? "tabs__link active"
                    : "tabs__link"
                }
                // changes
                // onClick={() => changeCampaignTab('Test')}
              >
                Test Campaigns
              </Link>
            </div>
            <div className="custom__container">
              <div className="table-responsive table__responsive--custom">
                <table className="table table__custom">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Email</th>
                      <th>Total Emails Sent</th>
                      <th>Total Not Sent</th>
                      <th>Total Unique Opens</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaignsLoader ? (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "#000" }}
                            spin
                          />
                        }
                        className="loader__full"
                      />
                    ) : (
                      campaignsList?.length > 0 &&
                      campaignsList?.map((cam, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <Link
                                to={`/campaign/overview/${cam.CampaignId}`}
                                state={{ data: cam, pathname: "/free-form-emails", campaignType: freeFormCampaignType }}
                                className="underline cursor"
                                style={{ color: "inherit" }}
                              >
                                {cam.CampaignName.replaceAll("PlainTextTest--FreeFormTest","")
                                .replaceAll("PlainTextLive--FreeFormLive","").replaceAll(
                                  "--FreeFormTest",
                                  ""
                                ).replaceAll("PlainTextTest--FreeFormTest","")
                                .replaceAll("PlainTextLive--FreeFormLive","")
                                  .replaceAll("FreeFormTest", "")
                                  .replaceAll("--FreeFormLive", "")
                                  .replaceAll("---", "")
                                  .replaceAll("FreeFormLive", "")
                                  .replaceAll("---", "")
                                  .replaceAll("PlainTextLive", "")
                                  .replaceAll("-", "")}
                              </Link>
                            </td>
                            <td>
                              <Link
                                // target={"_blank"}
                                to={`/send-email/${cam.CampaignId}`}
                                className="underline cursor theme-text-color"
                              >
                                Edit Email
                              </Link>
                            </td>

                            {cam?.DeliveredCount > 0 ? (
                              <>
                                <td>
                                  {parseInt(
                                    cam?.DeliveredCount
                                  )?.toLocaleString("en-US")}
                                </td>
                                <td>
                                  {parseInt(
                                    cam?.NotDeliveredCount
                                  )?.toLocaleString("en-US")}
                                </td>
                                <td>
                                  {parseInt(
                                    cam?.OpenedCount
                                  )?.toLocaleString("en-US")}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                              </>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {(campaignsList?.length === 0 && !campaignsLoader) && (
                <div className="table__blank">
                  <div className="table__blank--text">No Data</div>
                </div>
              )}
              {campaignsList?.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {size(campaignsList)} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {campaignsListPage - 1 >= 1 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={() => pageChangeCampaignsList("previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>
                    <>
                      {nextCamapaignStep - campaignsListPage > 0 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next"
                          onClick={() => pageChangeCampaignsList("next")}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--next disabled"
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {tab === "mailList" && (
          <>
            <div className="page__header">
              <div className="page__header--container">
                <div className="page__header--row align-items-center">
                  <div className="page__header--left">
                    <h1 className="page__header--title">Mailing List</h1>
                  </div>
                  <div className="page__header--right">
                    <button
                      onClick={() => {
                        setMailModalIsOpen(!mailModalIsOpen);
                      }}
                      type="button"
                      className="btn btn__purple"
                    >
                      Create Mailing List
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom__container">
              <div className="table-responsive table__responsive--custom">
                <table className="table table__custom">
                  <thead>
                    <tr>
                      <th>Mailing List Name</th>
                      <th>Number Of People</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mailListLoader ? (
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: "#000" }}
                            spin
                          />
                        }
                        className="loader__full"
                      />
                    ) : (
                      mailingListData?.length > 0 &&
                      mailingListData?.map((mail, i) => {
                        return (
                          <tr key={i}>
                            <td
                            // className='underline cursor'>
                            //   <span
                            //     onClick={() =>
                            //       navigate(`/mailing-list-detail/${mail.Id}`, {
                            //         state: { data: mail },
                            //       })
                            //     }
                            //   >
                            //     {mail.MailingDate}
                            //   </span>
                            //   {'  '}
                            //   <Edit
                            //     className='ms-4'
                            //     onClick={() => {
                            //       setEditMailOpen(true);
                            //       setMailingData({ ...mail });
                            //     }}
                            >
                              <Link
                                to={`/mailing-list-detail/${mail.Id}`}
                                state={{ date: mail }}
                                className="underline cursor"
                                style={{ color: "inherit" }}
                              >
                                <span>{mail.MailingDate}</span>
                              </Link>
                              <Edit
                                className="ms-4 cursor"
                                onClick={() => {
                                  setEditMailOpen(true);
                                  setMailingData({ ...mail });
                                }}
                              />
                            </td>
                            <td>
                              {parseInt(mail?.UserListIds)?.toLocaleString(
                                "en-US"
                              )}
                            </td>

                            <td className="text-end purple-loader">
    <button
                            onClick={() => exportMailingList(mail.Id,mail?.UserListIds,i)}
                                     className="add-btn add-btn-width me-3"
                            >
                              {isexportLoader[i] ? <Spin indicator={antIcon} /> : "Export Mailing List"}
                                
                              </button>
                              {/* <button
                                onClick={() =>
                                  navigate(`/mailing-list-detail/${mail.Id}`, {
                                    state: { data: mail },
                                  })
                                }
                                className='add-btn me-3'
                              > */}
                              <Link
                                to={`/mailing-list-detail/${mail.Id}`}
                                state={{ date: mail }}
                                className="add-btn me-3"
                              >
                                Add users to list
                              </Link>
                              {/* </button> */}
                              <button
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeletedRecord(mail);
                                }}
                                className="remove-btn"
                              >
                                {/* <Edit /> */}
                                Remove list
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {(mailingListData?.length === 0 && !mailListLoader) && (
                <div className="table__blank">
                  <div className="table__blank--text">No Data</div>
                </div>
              )}
              {mailingListData?.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {size(mailingListData)} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {mailingListPage - 1 >= 1 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={() => pageChangeGetMailingList("previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>
                    <>
                      {nextMailingStep - mailingListPage > 0 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--next"
                          onClick={() => pageChangeGetMailingList("next")}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn__default table__pagination--next disabled"
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {tab === "campaigns" && (
          <>
            <div className="page__header--left">
              <div className="inner-tabs-container">
                <button
                  className={
                    subTab === "capital-markets"
                      ? "tabs__link active"
                      : "tabs__link"
                  }
                  changes
                  onClick={() => changeSubTab("capital-markets")}
                >
                  Capital Markets
                </button>
                {"  "}
                <button
                  className={
                    subTab === "capital-markets-analytics"
                      ? "tabs__link active"
                      : "tabs__link"
                  }
                  changes
                  onClick={() => changeSubTab("capital-markets-analytics")}
                >
                  Capital Markets Analytics
                </button>
              </div>
            </div>
            {subTab === "capital-markets" ? (
              <>
                <div className="page__header">
                  <div className="page__header--container">
                    <div className="page__header--row align-items-center">
                      <div className="page__header--left">
                        <h1 className="page__header--title">
                          Capital Markets NewsLetter
                        </h1>
                      </div>
                      <div>
                        <button
                          onClick={() => {}}
                          type="button"
                          className="btn btn__purple"
                        >
                          Create Capital Markets Email
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="page__header">
                  <div className="page__header--container">
                    <div className="page__header--row align-items-center">
                      <div className="page__header--left">
                        <h1 className="page__header--title">Campaigns</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="custom__container">
                  <div className="table-responsive table__responsive--custom">
                    <table className="table table__custom">
                      <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>Total Emails Sent</th>
                          <th>Total Not Sent</th>
                          <th>Total Unique Opens</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignsLoader ? (
                          <Spin
                            size="large"
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 30, color: "#000" }}
                                spin
                              />
                            }
                            className="loader__full"
                          />
                        ) : (
                          campaignsList?.length > 0 &&
                          campaignsList?.map((cam, i) => {
                            return (
                              <tr key={i}>
                                <td
                                  onClick={() =>
                                    navigate(`/campaign/overview/${cam.id}`, {
                                      state: { data: cam },
                                    })
                                  }
                                  className="underline cursor"
                                >
                                  {cam.name}
                                </td>
                                {cam?.stats?.length > 0 ? (
                                  <>
                                    <td>
                                      {parseInt(
                                        cam?.stats[0].stats?.requests
                                      )?.toLocaleString("en-US")}
                                    </td>
                                    <td>
                                      {parseInt(
                                        cam?.stats[0]?.stats?.requests -
                                          cam?.stats[0]?.stats?.delivered
                                      )?.toLocaleString("en-US")}
                                    </td>
                                    <td>
                                      {parseInt(
                                        cam?.stats[0]?.stats?.unique_opens
                                      )?.toLocaleString("en-US")}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                  </>
                                )}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {(campaignsList?.length === 0 && !campaignsLoader) && (
                    <div className="table__blank">
                      <div className="table__blank--text">No Data</div>
                    </div>
                  )}
                  {campaignsList?.length > 0 && (
                    <div className="table__pagination">
                      <div className="table__pagination--results">
                        {size(campaignsList)} results
                      </div>
                      <div className="table__pagination--nav">
                        <>
                          {campaignsListPage - 1 >= 1 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--prev"
                              onClick={() =>
                                pageChangeCampaignsList("previous")
                              }
                            >
                              Previous
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--prev disabled"
                            >
                              Previous
                            </button>
                          )}
                        </>
                        <>
                          {nextCamapaignStep - campaignsListPage > 0 ? (
                            <button
                              type="button"
                              className="btn btn__default table__pagination--next"
                              onClick={() => pageChangeCampaignsList("next")}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled
                              className="btn btn__default table__pagination--next disabled"
                            >
                              Next
                            </button>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {/* Modal */}
        <ReactModal
          isOpen={primaryModalIsOpen}
          onRequestClose={primaryModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add Primary Tag</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Primary Tag</label>
                <div className="tag__group">
                  <SelectReact
                    onChange={(e) => {
                      setErrorBulktag("");
                      setBulkPrimaryTag({ ...e });
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={selectedThemeCategories}
                    //isMulti
                    options={secondaryTag}
                  />
                </div>
                {bulkErrorMessage && (
                  <div className="invalid-feedback">{bulkErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => primaryModalClose()}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => handlePrimaryTag()}
                type="button"
                className="btn btn__purple"
              >
                {tagLoader ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={deleteModal}
          onRequestClose={closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this ? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeMailingList()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={secondaryModalIsOpen}
          onRequestClose={secondaryModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add Secondary Tag</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
                <Close />
              </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">Secondary Tag</label>
                <div className="tag__group">
                  <SelectReact
                    onChange={(e) => {
                      setErrorBulktag("");
                      setBulkSecondayTag(e);
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={selectedThemeCategories}
                    isMulti
                    options={secondaryTag}
                  />
                </div>
                {bulkErrorMessage && (
                  <div className="invalid-feedback">{bulkErrorMessage}</div>
                )}
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => secondaryModalClose()}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSecondaryTag()}
                type="button"
                className="btn btn__purple"
              >
                {tagLoader ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Send Other News Email</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
              <Close />
            </button> */}
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">LT Weekly Date</label>
                {/* <Spin
                  spinning={categoryLoader}
                  indicator={<LoadingOutlined style={{ color: "#000" }} spin />}
                > */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => handleLTWeeklyDate(e.target.value)}
                >
                  {newsData?.map((news) => (
                    <option value={news.LTWeeklyDate}>
                      {news.LTWeeklyDate}
                    </option>
                  ))}
                </select>
                {/* </Spin> */}
              </div>
              <ul className="list list-custom-block">
                <Spin
                  spinning={categoryLoader}
                  indicator={<LoadingOutlined style={{ color: "#000" }} spin />}
                >
                  {categoriesList.map((category, index) => {
                    return (
                      <li className="items" key={index}>
                        <CheckboxComponent
                          index={index}
                          value={category}
                          handleCategoriesList={(e) =>
                            handleCategories(e.target.value)
                          }
                          categoriesArray={selectedCategories}
                        />
                      </li>
                    );
                  })}
                </Spin>
              </ul>
              {curatedArray.length > 0 && (
                <div className="curated__list-section">
                  <label className="label-primary">Curated List</label>
                  <ul className="list">
                    {curatedList?.map((tag, index) => {
                      return (
                        <li className="items" key={index}>
                          <Checkbox
                            index={index}
                            id={tag.Id}
                            value={tag.Name}
                            handleTags={(e) => handleCuratedTags(tag.Id)}
                            tagsArray={selectedTags}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div className="send__email-input">
                <label className="label-primary">
                  Send to:
                  <i>(enter emails and press Tab or Enter)</i>
                </label>
                <div className="tag__group">
                  <Select
                    value={selectedEmails}
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleEmails}
                  >
                    {emails}
                  </Select>
                  <div style={{ color: "red", fontSize: "13px" }}>
                    {emailError === "" ? "" : emailError}
                  </div>
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  setCategoriesList([]);
                  setCuratedArray([]);
                  setSelectedTags([]);
                  setSelectedEmails([]);
                  closeModal();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple"
                onClick={sendOtherNewsEmail}
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Send"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={mailModalIsOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Create Mailing List</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <form ref={ref} noValidate>
                  <div className="form-group">
                    <label className="label-primary">Mailing List Name</label>
                    <input
                      type="text"
                      name="mailName"
                      onChange={(e) => handleMailName(e.target.value)}
                      className="form-control"
                      value={mailName}
                      required
                    />
                    {/*                   <select
                      // style={{ marginBottom: "20px" }}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => handleMailName(e.target.value)}
                    >
                      {newsData?.map((news) => (
                        <option value={news.LTWeeklyDate}>
                          {news.LTWeeklyDate}
                        </option>
                      ))}
                    </select>*/}
                    {nameError !== "" && (
                      <div className="invalid-feedback">{nameError}</div>
                    )}
                  </div>
                  <div className="upload__file-section">
                    <label className="label-primary">
                      Mailing List <i>(optional)</i>
                    </label>
                    <div className="dropzone-file">
                      <button type="button" className="btn btn__purple">
                        Upload File
                      </button>
                      <CSVReader
                        cssClass="csv-reader-uploader"
                        onFileLoaded={(data) => {
                          fileHandler(data);
                          ref.current.reset();
                        }}
                        onError={fileHandler}
                        parserOptions={papaparseOptions}
                        inputId="ObiWan"
                      />
                    </div>
                    {cancel === true && (
                      <div className="upload-content">
                        <button
                          className="remove-csv-file"
                          onClick={() => isCancel()}
                        >
                          <CloseOutlined />
                        </button>
                        <img src="https://www.spreadsheetweb.com/wp-content/uploads/2021/01/How-to-avoid-formatting-change-on-CSV-files-in-Excel-00.png" />
                      </div>
                    )}
                    {uploadError && (
                      <div className="invalid-feedback">{errorMessage}</div>
                    )}
                  </div>
                </form>
                
              </div>
              
            </div>
            <div className="live-checkbox">
            <label className="custom__checkbox">
                    <input
                      // name="ShowSubscriptionInEmail"
                      value={isLive ? true : false}
                      checked={isLive}
                      onChange={(e) => onLiveListChecked(e.target.checked)}
                      type="checkbox"
                      className="custom__checkbox--input"
                    />
                    <span className="custom__checkbox--check"></span>
                    Is Live List?
                  </label>
                  </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  setData([]);
                  setUploadError(false);
                  setErrorMessage("");
                  setNameError("");
                  mailModalClose();
                  isCancel();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple uploadlistbuttonmin"
                onClick={() => uploadList()}
              >
                {isLoading ? <Spin indicator={antIcon} className="customSize"/> : "Upload List"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={editMailModalOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Mailing List Name</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <div className="form-group">
                  <label className="label-primary">
                    Update Mailing List Name
                  </label>
                  <input
                    type="text"
                    name="mailName"
                    onChange={(e) => handleMailListName(e.target.value)}
                    className="form-control"
                    value={mailingData?.MailingDate}
                    required
                  />
                  {nameError !== "" && (
                    <div className="invalid-feedback">{nameError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  setMailingData([]);
                  setEditMailOpen(false);
                  setNameError("");
                  CallApiForGetMailingList();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple"
                onClick={() => uploadListName()}
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Update List Name"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={editTagNameModal}
          onRequestClose={closeEditTagModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Tag Name</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <div className="form-group">
                  <label className="label-primary">
                    Update Tag Name
                  </label>
                  <input
                    type="text"
                    name="mailName"
                    onChange={(e) => handleUpdateTagName(e.target.value,tagName.i)}
                    className="form-control"
                    value={tagName.value}
                    required
                  />
                  {nameError !== "" && (
                    <div className="invalid-feedback">{nameError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  setNameError("");
                  setOldTagName("")
                  setTagName({})
                  setNewTagName("")
                  seteditTagNameModal(false);
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple"
                onClick={() => updateTagName()}
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Update Tag Name"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={deleteTagNameModal}
          onRequestClose={closeDeleteTagModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete Tag</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this tag? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeDeleteTagModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => deleteTagName()}
                className="btn btn__purple ms-2"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={createTagModalIsOpen}
          onRequestClose={closeCreateTagModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Create New Tag</h5>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <form ref={ref} noValidate>
                  <div className="form-group">
                    <label className="label-primary">Tag Name</label>
                    <input
                      type="text"
                      name="mailName"
                      onChange={(e) => handleCreateTagName(e.target.value)}
                      className="form-control"
                      value={createTag}
                      required
                    />
                    {tagNameError !== "" && (
                      <div className="invalid-feedback">{tagNameError}</div>
                    )}
                    <label className="label-primary mt-3">Select Header Category</label>
                                      <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => handleHeadertagName(headerCategories[e.target.value])}
                    >
                      <option value='-1'>Select</option>
                      {headerCategories?.map((c,index) => (
                        <option value={index} key={c.Id}>
                          {c.Title}
                        </option>
                      ))}
                    </select>
                    {nameError !== "" && (
                      <div className="invalid-feedback">{nameError}</div>
                    )}
                  </div>
                  
                </form>
                
              </div>
              
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  setErrorMessage("");
                  setNameError("");
                  closeCreateTagModal();
                  isCancel();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple minWidthCreateTagSave"
                onClick={() => saveNewTag()}
              >
                {isLoading ? <Spin indicator={antIcon} className="customSize" /> : "Save Tag Name"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={imageModalOpen}
          onRequestClose={closeImageModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          // shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="image-modal"
          centerd
          // className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="head__close">
              <button
                onClick={() => closeImageModal()}
                className="btn btn__close white-bg"
              >
                <Close />
              </button>
            </div>
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {lTWeeklyThemeData.length > 0 &&
                lTWeeklyThemeData?.map((item, i) => {
                  if (imageIndex === i) {
                    return (
                      <div key={i}>
                        <div className="img-body">
                          <img src={item?.Photo} alt="theme-img" />
                        </div>
                      </div>
                    );
                  }
                })}
            </Slider>
            <div style={{ textAlign: "center" }}>
              {imageIndex>0 && (
              <div className="left-arrow">
                <PrevArrow onClick={previous} disabled={isPrevDisabled} />
              </div>
              )}
              {imageIndex<99 && (
              <div className="right-arrow">
                <NextArrow onClick={next} disabled={isNextDisabled} />
              </div>
              )}
            </div>
          </div>
        </ReactModal>
        {/* Modal End */}
      </main>
    </>
  );
}
function AddLTWeeklyLink({ isExists, openNotificationWithIcon }) {
  return !isExists ? (
    <Link to="/add-ltweekly" className="btn btn__purple">
      Add LT Weekly
    </Link>
  ) : (
    <button
      onClick={() => {
        openNotificationWithIcon(
          "error",
          "Oops you have already created an LT Weekly for today."
        );
      }}
      type="button"
      className="btn btn__purple"
    >
      Add LT Weekly
    </button>
  );
}
